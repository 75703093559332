import React, { FC } from "react";
import PortableText from "./PortableText";

import { ContentBlockText as ContentBlock } from "lib/cms";
import MaxWidth from "./MaxWidth";

interface Props {
  contentBlock: ContentBlock;
  smallText?: boolean;
}

const ContentBlockText: FC<Props> = ({
  contentBlock: { backgroundColor, content },
  smallText,
}) => {
  return (
    <MaxWidth backgroundColor={backgroundColor}>
      <PortableText
        blocks={content}
        className={smallText ? "md:text-small" : undefined}
        indent={!!smallText}
      />
    </MaxWidth>
  );
};

export default ContentBlockText;
