import React, { FC } from "react";
import GatsbyLink from "gatsby-link";

import { TailwindColor } from "lib/cms";

interface Props {
  to: string;
  backgroundColor?: TailwindColor;
}

const ButtonLink: FC<Props> = ({ backgroundColor = "grey", children, to }) => {
  return (
    <GatsbyLink
      className={`bg-${backgroundColor} block md:inline-block w-full md:w-auto min-w-32 mt-2 md:mt-0 pb-1.4 px-4 text-medium md:text-button text-center`}
      to={to}
    >
      {children}
    </GatsbyLink>
  );
};

export default ButtonLink;
