import React, { FC } from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

import { PodcastsPageQuery } from "lib/cms";

import PageWrapper from "components/PageWrapper";
import ContentBlocks from "components/ContentBlocks";

import NotFoundPage from "./404";
import FullWidth from "components/FullWidth";
import MaxWidth from "components/MaxWidth";
import PortableText from "components/PortableText";
import ButtonLink from "components/ButtonLink";
import MetaData from "components/MetaData";

interface Props {
  data: PodcastsPageQuery;
  location: Location;
}

const PodcastsPage: FC<Props> = ({ data: { page } }) => {
  return page ? (
    <PageWrapper backgroundColor={page.backgroundColor}>
      <MetaData title="Podcasts" />
      <ContentBlocks blocks={page.content} />
      <div className="py-2.5 md:py-9" />
      <FullWidth>
        {page.podcasts.map((podcast, index) => (
          <MaxWidth
            key={podcast._id}
            backgroundColor={index % 2 ? "grey" : "white"}
          >
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="flex-shrink-0 w-full md:h-50 md:w-50 md:mr-8">
                <GatsbyImage fluid={podcast.thumbnail.asset.fluid} />
              </div>
              <div className="flex-grow flex flex-col pt-2.5 md:pt-1 md:pb-9">
                <h2 className="uppercase mb-2 text-medium md:text-base">
                  {podcast.name}
                </h2>
                <PortableText
                  blocks={podcast.tagline}
                  className="flex-grow md:text-small font-normal"
                  indent={false}
                />
                <div>
                  <ButtonLink
                    to={`/podcasts/${podcast.slug.current}`}
                    backgroundColor={index % 2 ? "white" : "grey"}
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
            </div>
          </MaxWidth>
        ))}
      </FullWidth>
      <div className="py-2.5 md:py-9" />
      <FullWidth>
        <MaxWidth backgroundColor="bubblegum">
          <p className="text-mobile-base md:text-base text-center py-4 md:py-10">
            New podcasts coming soon...
          </p>
        </MaxWidth>
      </FullWidth>
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query PodcastsPage {
    page: sanityPodcasts {
      backgroundColor
      content: content {
        _key
        backgroundColor
        content: _rawContent
      }
      podcasts {
        _id
        name
        slug {
          current
        }
        tagline: _rawTagline
        thumbnail {
          asset {
            _id
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default PodcastsPage;
