import React, { FC } from "react";

import { ContentBlockText as ContentBlock } from "lib/cms";

import ContentBlockText from "./ContentBlockText";
import FullWidth from "./FullWidth";

interface Props {
  blocks: ContentBlock[];
  smallText?: boolean;
}

const ContentBlocks: FC<Props> = ({ blocks, smallText }) => {
  return (
    <FullWidth>
      {blocks.map(contentBlock => (
        <ContentBlockText
          contentBlock={contentBlock}
          key={contentBlock._key}
          smallText={smallText}
        />
      ))}
    </FullWidth>
  );
};

export default ContentBlocks;
